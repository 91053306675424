'use client'

import { Box, Flex } from 'theme-ui'
import { FC, useEffect } from 'react'
import cx from 'classnames'
import {
  Button,
  AddressLookup,
  validateAddress,
  useModalContext,
  ADDRESS_INPUT_ERROR,
  ModalView,
  ModalErrorView,
  useMyReportsContext,
} from '@propertylens/ui-kit'
import { emitTrackingEvent } from '@propertylens/app-commons'

import { useCheckout } from '~/context/checkout'

interface MapSearchType {
  defaultAddress?: string
  buttonLabel?: string
  buttonRadius?: string
  buttonColor?: string
  buttonIconColor?: string
  buttonIcon?: string
  buttonIconSize?: string
  inputLabel?: string
  inputRadius?: string
  redirectUrl: string
  isSamePageCheckout: boolean
  isColumnLayout: boolean
}

const getInputClasses = (isColumnLayout, buttonIcon) => {
  if (isColumnLayout) {
    return 'mt-9 font-[raleway]';
  }
  return buttonIcon
    ? 'md:text-2xl mt-6 font-[raleway]'
    : 'md:text-3xl mt-9 font-[raleway]';
};

const getInputWrapperClasses = (buttonIcon, inputRadius) => {
  const padding = buttonIcon ? 'pt-0 pb-4' : 'pb-5';
  return `px-7 ${padding} bg-white h-full mb-4 font-[raleway] rounded-${inputRadius}`;
};


const MapSearch: FC<MapSearchType> = ({
  defaultAddress = 'ex. 123 Main Street, Boston, MA 01234',
  buttonLabel = 'Get PropertyLens Report',
  buttonRadius = 'sm',
  buttonColor,
  buttonIconColor,
  buttonIcon,
  buttonIconSize,
  inputLabel = 'Search by address',
  inputRadius = 'medium',
  redirectUrl,
  isSamePageCheckout,
  isColumnLayout,
}) => {
  const {
    selectedPlace,
    setModalView,
    autocompleteInputValue,
    setModalErrorView,
    setAddressInputError,
    setAddressInputErrorType,
    openGetReports,
    setShouldBePopulated,
    existingReport,
    setIsAllowed,
    setWarning,
    isAllowed,
    warning,
    setAddressLookupRedirectURL,
  } = useModalContext()
  const { setReport, setAddress } = useCheckout()
  const googleAPIKey = process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY
  const baseCx = cx(
    {
      'w-2': buttonIconSize === 'xs',
      'w-4': buttonIconSize === 'sm',
      'w-6': buttonIconSize === 'md',
      'w-8': buttonIconSize === 'lg',
      'w-10': buttonIconSize === 'xl',
    },
    'flex items-center justify-center'
  )
  const fontSize = {
    xs: '1rem',
    sm: '1.5rem',
    md: '2rem',
    lg: ['2rem', '2.5rem'],
    xl: ['2.25rem', '3rem'],
  }
  useEffect(() => {
    if (!isSamePageCheckout) {
      setAddress(null)
      setReport(null)
    }
  }, [isSamePageCheckout, setAddress, setReport])

  useEffect(() => {
    if (redirectUrl) {
      setAddressLookupRedirectURL(redirectUrl)
    }
  }, [redirectUrl, setAddressLookupRedirectURL])

  const { getReports } = useMyReportsContext()
  useEffect(() => {
    getReports()
  }, [])

  const handleGetReport = async () => {
    try {
      emitTrackingEvent('Address Searched', {
        address: {
          ...selectedPlace,
        },
        component: 'MapSearch',
      })

      if (!isAllowed || (warning && warning.message !== null)) {
        setShouldBePopulated(true)
        openGetReports()
        return
      }

      if (!autocompleteInputValue) {
        emitTrackingEvent('Address Error', {
          address: {
            ...selectedPlace,
          },
          reason: 'INVALID_ADDRESS',
          validationResult: null,
        })
        // open empty address1 field without an error
        setAddressInputError(false)
        openGetReports()
        return
      }

      const validateAddressResult = await validateAddress(
        selectedPlace,
        googleAPIKey
      )

      if (!validateAddressResult) {
        // this will be shown in base form modal
        // and trigger modal
        emitTrackingEvent('Address Error', {
          address: {
            ...selectedPlace,
          },
          reason: 'UNABLE_TO_VALIDATE_ADDRESS',
          validationResult: null,
        })
        setAddressInputError(true)
        setAddressInputErrorType(ADDRESS_INPUT_ERROR.INVALID_ADDRESS)
        openGetReports()
        return
      }

      if (
        !validateAddressResult.verdict?.addressComplete &&
        !validateAddressResult.metadata
      ) {
        emitTrackingEvent('Address Error', {
          address: {
            ...selectedPlace,
          },
          reason: 'INVALID_ADDRESS',
          validationResult: validateAddressResult?.verdict,
        })
        setAddressInputError(true)
        setAddressInputErrorType(ADDRESS_INPUT_ERROR.INVALID_ADDRESS)
        openGetReports()
        return
      }

      if (existingReport) {
        setShouldBePopulated(true)
        openGetReports()
        return
      }

      if (
        validateAddressResult.metadata &&
        validateAddressResult.metadata.business
      ) {
        emitTrackingEvent('Commercial Address', {
          address: { ...selectedPlace },
        })
        setIsAllowed(true)
        setWarning({
          message: (
            <p>
              This looks like a commercial property, which may have limited
              information, are you sure you want to proceed?
            </p>
          ),
          type: 'commercialAddress',
        })
        setShouldBePopulated(true)
        openGetReports()
        return
      }

      const missingComponentTypes =
        validateAddressResult?.address?.missingComponentTypes

      if (missingComponentTypes && missingComponentTypes.length > 0) {
        if (missingComponentTypes.includes('subpremise')) {
          // and trigger modal
          setModalView(ModalView.SecondaryForm)
          openGetReports()
        } else {
          setAddressInputError(true)
          setAddressInputErrorType(ADDRESS_INPUT_ERROR.INVALID_ADDRESS)
          openGetReports()
        }
      } else {
        // and trigger modal
        setModalView(ModalView.Preview)
        openGetReports()
      }
    } catch (error) {
      // and trigger modal
      setModalView(ModalView.Error)
      setModalErrorView(ModalErrorView.ErrorOnBase)
      openGetReports()
    }
  }

  if (!redirectUrl) {
    return <h1>MapSearch: redirectUrl prop is required</h1>
  }

  return (
    <Box sx={{ position: 'relative' }}>
      <AddressLookup
        parentComponent="MapSearch"
        googleAPIKey={googleAPIKey}
        inputLabel={inputLabel}
        placeholder={defaultAddress}
        classNames={{
          input: getInputClasses(isColumnLayout, buttonIcon),
          inputWrapper: getInputWrapperClasses(buttonIcon, inputRadius),
        }}
      />
      <Flex
        sx={{
          bottom: 0,
          flexDirection: isColumnLayout ? 'row' : [null, null, 'column'],
          justifyContent: 'center',
          left: buttonIcon ? 'auto' : [0, null, 'auto'],
          position: isColumnLayout
            ? 'relative'
            : buttonIcon
            ? 'absolute'
            : ['relative', 'relative', 'absolute'],
          right: isColumnLayout
            ? 'auto'
            : buttonIcon
            ? ['6px', '4px', 2]
            : [0, null, 4],
          top: buttonIcon ? ['8px', '2px', '-14px'] : [null, null, 0],
        }}
      >
        <Button
          radius={buttonRadius}
          type="button"
          color="primary"
          size="lg"
          onClick={handleGetReport}
          data-attribute="gtm-checkout-address-search"
          aria-label={buttonLabel || "Search for Property"}
          className={cx(
            '',
            buttonIcon &&
              'min-w-[50px] md:min-w-[60px] h-[50px] md:h-[60px] pt-[12px] pb-[14px] px-0'
          )}
          sx={{
            backgroundColor: buttonColor && buttonColor,
          }}
        >
          {buttonIcon ? (
            <div
              className={baseCx}
              sx={{
                i: {
                  color: buttonIconColor,
                  fontSize: fontSize[buttonIconSize],
                },
              }}
            >
              <i className={`ri-${buttonIcon}`}></i>
            </div>
          ) : (
            buttonLabel
          )}
        </Button>
      </Flex>
    </Box>
  )
}

export default MapSearch
